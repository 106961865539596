<template>
	<div class="m-lg:px-4 px-4 flex flex-col">
		<IndexServerlistSEOServerList :seo-servers="seoServers" v-if="filter === 'top'"/>

		<div class="mb-2"
		     :class="{
							'flex': filter === 'user' || filter === 'favourites',
							'justify-between': filter === 'user' || filter === 'favourites',
							'shimmer-parent': status === 'pending',
						 }">

			<IndexServerlistServerListTitle
					:SERVERS_ON_PAGE="SERVERS_ON_PAGE"
					:serversLength="data?.servers?.length ?? 0"
					:featuredServersLength="data?.featured_servers_length ?? 0"
			/>

			<div v-if="filter === 'user' || filter === 'favourites'"
			     class="flex justify-end m-lg:hidden">
				<NuxtLink to="/server/add" class="flex gap-1 items-center text-white-0 bg-blue-700 rounded-lg px-3 py-2 text-sm
													  hover:bg-blue-800">
					Add New Server
				</NuxtLink>
			</div>
		</div>

		<div class="flex gap-3 m-lg:justify-around h-9"
		     v-if="filter !== 'user' && filter !== 'favourites'">
			<select v-model="sortFilter"
			        class="bg-gray-light rounded-xl px-2.5 py-1.5 w-30 border border-gray-400 m-lg:w-fit"
			        label="Sort by"
			        aria-label="sortFilter">
				<option value="ranked">Top Ranked</option>
				<option value="players">Top Players</option>
				<option value="recent">Most Recent</option>
			</select>
			<select v-model="platformFilter"
			        class="bg-gray-light rounded-xl px-2.5 py-1.5 w-30 border border-gray-400 m-lg:w-fit"
			        label="Platform"
			        id="platformFilter"
			        aria-label="platformFilter">
				<option value="all">All servers</option>
				<option value="java">Java</option>
				<option value="bedrock">Bedrock</option>
			</select>
		</div>
		<div class="flex justify-end m-lg:hidden"
		     v-if="filter !== 'user' && filter !== 'favourites'">
			<button class="flex gap-1 items-center bg-gray-400 rounded-lg px-2 py-0.5 cursor-pointer hover:bg-gray-600"
			        @click="addNewServer">
				<span>Add your server</span>
				<img src="/icons/right_arrow_small.svg" alt="Add Server Icon" class="w-3 h-4"
				     width="6" height="8"/>
			</button>
		</div>

		<template v-if="data && data.servers && data.servers.length > 0">
			<div class="serverlist "
			     :class="{
						 'shimmer': status === 'pending',
						 'server-tile-done': status === 'success'
						 }"
			>
				<!--					v-for="(server, index) in data.servers"-->
				<TilesServerListTile
						v-for="(server, index) in data.servers"
						:key="`server_tile_${server.id}`"
						:style="{animationDelay: `${index * 0.1}s`}"
						:server="server"
						:index="index"
						:featured-servers-length="data.featured_servers_length"
				/>
			</div>
		</template>

		<div v-else
		     class="flex flex-col gap-4 justify-center items-center mt-10 mb-2">
<span class="text-2xl bg-white-0 bg-opacity-60 px-4 py-2 rounded-xl">
				<template v-if="filter === 'tag'">
					<template v-if="currentTag?.value">
					No servers found for the tag <b>{{
							currentTag?.name ?? ""
						}}</b>.
					</template>
					<template v-else>
						This tag does not exist.
					</template>
				</template>
				<template v-else-if="filter === 'user'">You haven’t added any servers yet.</template>
				<template
						v-else-if="filter === 'favourites'">You haven’t marked any servers as favorites yet.</template>
				<template v-else-if="filter === 'search'">No servers matching the search term <b v-if="search">{{
						search.replaceAll("+", " ")
					}}</b>.</template>
					<template v-else>No servers found.</template>
			</span>
			<NuxtLink v-if="data?.total_servers ?? 0 > 0" :to="firstPageLink"
			          class="first-page  px-3 py-1 text-blue-500 border-2 border-blue-500 py-1.5 flex justify-center gap-2 items-center rounded-lg
							   hover:text-white-0 hover:bg-blue-500">
				<span>Go back to first page</span>
				<img alt="Arrow pointing to the right" src="/icons/right_arrow_small_white.svg"
				     width="10" height="10">
			</NuxtLink>
		</div>
		<IndexPaging v-if="maxPages > 1" :current-page="currentPage" :max-page="maxPages"/>
	</div>
</template>

<script setup>
import { sanitizeBanner, titleEncode } from "~/utils/helpers.js";
import { notify } from '@kyvg/vue3-notification';

const SERVERS_ON_PAGE = 15;

const route = useRoute();

const { setPopup } = usePopup();
const user = useUser();

const filter = computed(() => getFilter(route.name));
const currentPage = computed(() => route.params.page ? Number(route.params.page) : 1);

const maxPages = computed(() => Math.ceil(data?.value?.total_servers ?? 0 / SERVERS_ON_PAGE));

const sortFilter = ref("ranked");
const platformFilter = ref("all");

const search = computed(() => route.params.search);
const tag = computed(() => route.params.tag);
const { currentTag } = useTags();

const seoServers = ref([]);

let abortController = new AbortController();

const { data, status, refresh } = await useAsyncData("serverList", async () => {
	if (abortController) {
		abortController.abort();
	}

	const query = {
		filters: {
			type: filter.value,
			platform: platformFilter.value,
		},
		sorts: {
			[sortFilter.value]: -1,
		},
		limit: SERVERS_ON_PAGE,
		offset: (currentPage.value - 1) * SERVERS_ON_PAGE,
	};

	if (filter.value === 'tag') {
		query.filters.tag = tag.value;
	}

	if (filter.value === 'search') {
		query.filters.query = search.value.replace("<", "&lt;").replace(">", "&gt;").replace("&", "&amp;");
	}

	if (filter.value === 'user') {
		query.sorts = {
			creation_date: -1,
		}
	}

	abortController = new AbortController();
	return $fetch("/api/server/many", {
		method: "POST",
		body: query,
		signal: abortController.signal,
	});
}, {
	lazy: true,
	transform: async (localData) => {
		for (const server of localData.servers) {
			server.poster = "/no_banner.webp"
			if (server.banner) {
				server.banner = sanitizeBanner(server.banner);
			}
			if (!server.description) {
				server.description = ""
			} else {
				server.description = server.description.replace(/<\/?[^>]+>/ig, " ");
			}
			server.href = "/server/" + titleEncode(server.title, server.custom_title_url) + "-" + server.id
		}

		if (!localData.servers.length) {
			setResponseStatus(404);
		}

		const updateSEOList = () => {
			if (filter.value === 'tag') {
				// Set SEO servers
				seoServers.value = localData.servers.filter((server, index) => !server.featured);
			} else {
				seoServers.value = [];
			}
		}

		if (import.meta.server) {
			updateSEOList();
		} else {
			setTimeout(() => {
				updateSEOList();
			}, 1000);
		}

		return localData;
	},
});

watch([filter, currentPage, sortFilter, platformFilter, () => route.params.tag, () => route.params.search], () => {
	if (filter.value !== "tag") {
		currentTag.value = null;
	}

	refresh();
});

// Legacy redirect
const params = route.query
if (params && (params.action || params.listingID)) {
	setResponseStatus(410);
	await navigateTo("/")
}

watch([filter, () => route.params.tag, () => route.params.search], () => {
	sortFilter.value = "ranked";
	platformFilter.value = "all";
});

// const isAdminCookie = useCookie("is_admin");

// async function refreshIPCopyData() {
// 	if (!isAdminCookie.value) return;
// 	for (const server of servers.value) {
// 		try {
// 			server.ipCopyData = await $fetch(
// 				"/api/ipcopy/" +
// 				server.ip +
// 				(server.port && server.port != 25565 ? ":" + server.port : ""),
// 				{
// 					ignoreResponseError: true,
// 				}
// 			);
// 		} catch (e) {
// 			break;
// 		}
// 	}
// }

// onMounted(() => {
// 	refreshIPCopyData();
// });

function addNewServer() {
	if (!user.value) {
		navigateTo("?redirect=/server/add");

		setPopup('LoginPopup');

		notify({
			type: "error",
			title: "Login Required",
			text: "You must login to access this page.",
		})
	} else {
		navigateTo("/server/add");
	}
}

const firstPageLink = computed(() => {
	const currentPath = route.path;
	return currentPath.replace(/\/[0-9]+$/, "");
});
</script>


<style lang="scss" scoped>
.ip-address {
  font-family: 'Azeret Mono', sans-serif;
}

.online-players-cube {
  width: .5em;
  height: .5em;
  background: #D9D9D9;
  display: inline-block;
  margin-right: 0.5em;
}

.first-page {
  img {
    filter: brightness(0) saturate(100%) invert(39%) sepia(62%) saturate(4584%) hue-rotate(211deg) brightness(90%) contrast(96%);
  }

  &:hover {
    img {
      filter: none;
    }
  }
}

.server-tile-done > div {
  animation: showServerTile 0.5s ease forwards;
  transform: translateY(20px);
}

@keyframes showServerTile {
  from {
    transform: translateY(20px);
  }

  to {
    transform: translateY(0);
  }

}
</style>

<style lang="scss">
.shimmer::after,
.shimmer-parent .shimmer-child::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  pointer-events: none;

  background: linear-gradient(-90deg, #eee 40%, #fafafa 50%, #eee 60%);
  background-size: 200% 200%;

  animation: shimmer 1.0s infinite linear;
  opacity: 0.75;
  user-select: none;
}

.shimmer,
.shimmer-child {
  position: relative;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}
</style>