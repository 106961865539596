<template>
	<div class="server-tile relative">
		<div class="content-center m-lg:mb-3">
			<div class="flex justify-center px-1 gap-3">
			<span v-if="!server.featured || index >= featuredServersLength" class="text-4xl">
				#{{ (currentPage - 1) * 15 + index - featuredServersLength + 1 }}
			</span>
				<div v-else class="flex items-center justify-center gap-3">
					<div :class="sponsorInfo ? 'hint--top hint--medium' : ''" :aria-label="sponsorInfo">
						<img src="/icons/star.svg" alt="Featured Server Icon" width="30" height="29"/>
					</div>
					<IndexServerlistBadgesSponsorBadge v-if="server.featured" classes="lg:hidden"/>
				</div>
			</div>
		</div>
		<div class="m-lg:flex m-lg:flex-col m-lg:items-center m-lg:gap-2">
			<div class="flex gap-1 mb-1">
				<NuxtLink :to="server.href"
				          :prefetch="true"
				          :prefetch-on="{ visibility: true, interaction: true }"
				          class="flex items-center w-fit border border-gray-600 bg-white-0 p-1 px-2 rounded-lg">
					<img
							v-if="favicon"
							class="w-4 h-4 mr-1 m-lg:w-5 m-lg:h-5"
							:src="favicon"
							alt="Server Icon"
							width="64" height="64"
							loading="lazy"
					/>
					<h3 class="text-sm m-lg:text-base font-bold">{{ server.title }}</h3>
				</NuxtLink>
				<IndexServerlistBadgesSponsorBadge v-if="server.featured && index < featuredServersLength"
				                                   classes="m-lg:hidden"/>
			</div>
			<IndexBannerComponent
					:title="server.title"
					:banner="server.banner"
					:href="server.href"
			/>
			<div class="flex justify-between mt-1.5 m-lg:hidden">
				<IndexServerlistBadgesServerPlatformBadge :platform="platform" :ip="server.ip"/>
				<IndexServerlistBadgesIPAddressBadge :ip="server.ip" :platform="platform"/>
			</div>
		</div>
		<div class="">
			<div class="flex justify-end mb-2 m-lg:hidden">
				<IndexServerlistBadgesPlayersBadge :status="server.statusString"/>
			</div>
			<p class="font-normal text-sm pl-2 mb-1.5 break-word">
				{{ server.description }}
			</p>
			<div class="lg:hidden flex justify-end m-lg:justify-center m-lg:gap-4 m-lg:my-4">
				<IndexServerlistBadgesPlayersBadge :status="server.statusString"/>
				<IndexServerlistBadgesServerPlatformBadge :platform="platform" :ip="server.ip"/>
			</div>
			<div class="lg:hidden w-full flex justify-center">
				<IndexServerlistBadgesIPAddressBadge :ip="server.ip"
				                                     classes="p-1.5 px-3 w-fit"
				                                     tooltip-placement="top"
				                                     :platform="platform"
				/>
			</div>
			<div class="flex justify-end gap-3 m-lg:justify-center m-lg:mt-2 m-lg:mx-3">
				<NuxtLink v-if="filter === 'user'"
				          :to="`/server/${server._id}/edit`"
				          class="flex bg-gray-400 p-1.5 px-2.5 text-sm gap-1 rounded-2xl
						   m-lg:text-base m-lg:p-1.5 m-lg:px-5 m-lg:gap-2 m-lg:w-full m-lg:justify-center
						   hover:bg-gray-600 w-50">
					<span>Edit</span>
					<img
							src="/icons/edit_black.svg" alt="View Server Icon"
							class="w-4 h-4 self-center" width="24" height="24">
				</NuxtLink>
				<NuxtLink
						:to="server.href"
						:prefetch="true"
						:prefetch-on="{ visibility: true, interaction: true }"
						class="flex bg-gray-400 p-1.5 px-2.5 text-sm gap-1 rounded-2xl
						   m-lg:text-base m-lg:p-1.5 m-lg:px-5 m-lg:gap-2 m-lg:mt-1 m-lg:w-full m-lg:justify-center
						   hover:bg-gray-600 w-50">
					<span>View</span>
					<img
							src="/icons/right_arrow_small.svg" alt="View Server Icon"
							class="w-2 h-2 self-center" width="6" height="8">
				</NuxtLink>
			</div>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	server: Object,
	index: Number,
	featuredServersLength: Number,
});

const route = useRoute();

const { currentTag } = useTags()
const filter = computed(() => getFilter(route.name));

const currentPage = computed(() => route.params.page ? Number(route.params.page) : 1);

const sponsorInfo = computed(() => {
	if (filter.value === "tag") {
		return "This is a sponsored server. It may not offer the " + currentTag.value?.name +
			" gamemode, but it's still worth exploring!"
	} else if (filter.value === "search") {
		return "This is a sponsored server. It may not offer the " + route.params.search +
			" gamemode, but it's still worth exploring!"
	} else {
		return null
	}
});

const platform = getPlatform(props.server);

const favicon = computed(() => props.server?.favicon ? `/api/server/${props.server.id}/favicon` : null);
</script>