<template>
	<div class="mt-32 flex gap-2 justify-center">
		<!--		Back button     -->
		<IndexPagingButton
				:link="currentPath === '/rank' && currentPage - 1 <= 1 ? '/' : `${currentPath}` + (currentPage - 1 <= 1 ? '' : `/${currentPage - 1}`)"
				:disabled="currentPage <= 1"
				classes="flex gap-2">
			<span>&lt</span>
			<span>Back</span>
		</IndexPagingButton>

		<!--		Previous pages     -->
		<IndexPagingButton v-for="page in previousPages" :key="'page-' + page"
		                   :link="currentPath === '/rank' && page < 2 ? '/' : `${currentPath}` + (page !== 1 && page !== 0 ? `/${page}` : '')"
		                   :content="page"/>


		<!--		Current page     -->
		<IndexPagingButton :content="currentPage"/>

		<!--		Next pages     -->
		<IndexPagingButton v-for="page in nextPages" :key="'page-' + page"
		                   :link="`${currentPath}/${page}`" :content="page"/>

		<!--		Next button     -->
		<IndexPagingButton :link="`${currentPath}/${props.currentPage + 1}`" :disabled="currentPage >= props.maxPage"
		                   classes="flex gap-2">
			<span>Next</span>
			<span>&gt</span>
		</IndexPagingButton>
	</div>
</template>

<script setup>
const props = defineProps({
	maxPage: {
		type: Number,
		required: true,
	},
	currentPage: {
		type: Number,
		required: true,
	},
});

const route = useRoute();

const currentPath = ref(getCurrentPath(route.fullPath));

function getCurrentPath(fullPath) {
	let path = fullPath;

	if (/\/(login|index|$).*$/.test(path)) {
		path = "/rank";
	}

	if (/\/\d+$/.test(path)) {
		path = path.replace(/\/\d+$/, "");
	}

	if (/\/$/.test(path)) {
		path = path.slice(0, -1);
	}

	return path;
}

const nextPages = ref([]);
const previousPages = ref([]);

watch(() => route.fullPath, () => {
	currentPath.value = getCurrentPath(route.fullPath);

	let prev = [];
	let next = [];

	for (let i = props.currentPage - 1; i >= Math.max(props.currentPage - 4, 1); i--) {
		prev.unshift(i);
	}

	for (let i = props.currentPage + 1; i <= Math.min(props.currentPage + 4, props.maxPage); i++) {
		next.push(i);
	}

// Adjust the number of pages if there are too many in one direction
	while (prev.length + next.length > 4) {
		if (prev.length > next.length) {
			prev.shift(); // Remove from the beginning of prev
		} else {
			next.pop(); // Remove from the end of next
		}
	}

	previousPages.value = prev;
	nextPages.value = next;
}, {
	immediate: true
});
</script>