<template>
	<table class="seo-serverlist">
		<thead>
		<tr>
			<th>Rank</th>
			<th>Players</th>
			<th>IP Address</th>
		</tr>
		</thead>
		<tbody>
		<tr v-for="(server, index) in servers"
		    :key="'hidden_server_' + index + '_' + server._id"
		>
			<td>{{ index + 1 }}</td>
			<td>Players: {{ server.status?.players?.online }}/{{ server.status?.players?.max }}</td>
			<td>{{ server.ip }}</td>
		</tr>
		</tbody>
	</table>
</template>

<script setup>
const props = defineProps({
	seoServers: {
		type: Array,
		required: true,
	},
});

const servers = toRef(props, 'seoServers');
</script>

<style lang="scss" scoped>
.seo-serverlist {
  position: absolute;
  transform: translate(-10000px, -10000px);
}
</style>