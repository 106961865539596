<template>
	<div class="shimmer-child">
		<template v-if="filter === 'tag'">
			<h1 class="text-3xl">Minecraft {{ currentTagName ?? "..." }} Servers</h1>
			<h2 class="text-lg font-normal">Minecraft servers tagged as
				<b>{{ currentTagName ?? "..." }}</b>
				<span v-if="serversLength > 0">
					({{ serversIndexes }})
				</span>
			</h2>
		</template>
		<template v-else-if="filter === 'user'">
			<h1 class="text-3xl">Your Servers</h1>
		</template>
		<template v-else-if="filter === 'favourites'">
			<h1 class="text-3xl">Favourite Minecraft Servers</h1>
		</template>
		<template v-else-if="filter === 'search'">
			<h1 class="text-3xl">Minecraft Servers by {{ search }}</h1>
		</template>
		<template v-else>
			<h1 class="text-3xl">Minecraft Server List</h1>
			<h2 class="text-lg font-normal">Minecraft Servers</h2>
			<h3 class="text-lg font-normal">Find the best Minecraft multiplayer servers here.</h3>
		</template>
	</div>
</template>

<script setup>
const props = defineProps({
	SERVERS_ON_PAGE: Number,
	serversLength: Number,
	featuredServersLength: Number,
});

const serversLength = toRef(props, 'serversLength');

const route = useRoute();
const currentPage = computed(() => route.params.page ? Number(route.params.page) : 1);

const search = computed(() => (route.params?.search ?? "").replaceAll("+", " "));
const filter = computed(() => getFilter(route.name));
const { currentTag } = useTags();
const currentTagName = computed(() => currentTag.value?.name);
const serversIndexes = computed(() => {
	const start = (currentPage.value - 1) * props.SERVERS_ON_PAGE + 1;
	const end = start + props.serversLength - props.featuredServersLength - 1;
	return `${start}-${end}`;
});
</script>